import type { ModeConfiguration, ThemeConfiguration } from "@/Types/configuration";

const allowedColorScheme: ReadonlyArray<string> = ['default', 'dark', 'light'];
const allowedColorTheme: ReadonlyArray<string> = ['dark-default', 'light-default'];
const theme: ThemeConfiguration = {
    attributeColorScheme: 'data-gd-color-scheme',
    attributeColorTheme: 'data-gd-color-theme',
    keyLocalStorageColorScheme: 'gd-layout-color-scheme',
    keyLocalStorageColorTheme: 'gd-layout-color-theme',
    defaultColorScheme: 'default',
    defaultColorTheme: 'dark-default',
};

const allowedLayoutMode: ReadonlyArray<string> = ['default', 'minimize', 'maximize'];
const mode: ModeConfiguration = {
    attribute: 'data-gd-layout-mode',
    keyLocalStorage: 'gd-layout-mode',
    default: 'default',
};

export { 
    theme, allowedColorScheme, allowedColorTheme,
    mode, allowedLayoutMode,
};