import { allowedColorScheme, allowedColorTheme, allowedLayoutMode, mode, theme as themeConfiguration } from "@/Configuration/layout";
import type { ModeContract, ThemeInterface } from "@/Types/module";

export class Theme implements ThemeInterface
{
    /**
     * HTML tag element.
     * 
     * @type { HTMLHtmlElement | null }
     */
    private readonly HTML_ELEMENT: HTMLHtmlElement|null = document.querySelector('html');

    /**
     * Attribute color scheme.
     * 
     * @type { string }
     */
    private readonly ATTRIBUTE_COLOR_SCHEME: string;

    /**
     * Attribute color theme.
     * 
     * @type { string }
     */
    private readonly ATTRIBUTE_COLOR_THEME: string;

    /**
     * Key local storage color scheme.
     * 
     * @type { string }
     */
    private readonly KEY_LOCAL_STORAGE_COLOR_SCHEME: string;

    /**
     * Key local storage color theme.
     * 
     * @type { string }
     */
    private readonly KEY_LOCAL_STORAGE_COLOR_THEME: string;

    /**
     * Default color scheme.
     * 
     * @type { string }
     */
    private readonly DEFAULT_COLOR_SCHEME: string;

    /**
     * Default color theme.
     * 
     * @type { string }
     */
    private readonly DEFAULT_COLOR_THEME: string;

    /**
     * Allowed color scheme.
     * 
     * @type { ReadonlyArray<string> }
     */
    private readonly ALLOWED_COLOR_SCHEME: ReadonlyArray<string>;

    /**
     * Allowed color theme.
     * 
     * @type { ReadonlyArray<string> }
     */
    private readonly ALLOWED_COLOR_THEME: ReadonlyArray<string>;

    /**
     * Create a new instance.
     */
    constructor()
    {
        this.ATTRIBUTE_COLOR_SCHEME = themeConfiguration.attributeColorScheme;
        this.ATTRIBUTE_COLOR_THEME = themeConfiguration.attributeColorTheme;
        this.KEY_LOCAL_STORAGE_COLOR_SCHEME = themeConfiguration.keyLocalStorageColorScheme;
        this.KEY_LOCAL_STORAGE_COLOR_THEME = themeConfiguration.keyLocalStorageColorTheme;
        this.DEFAULT_COLOR_SCHEME = themeConfiguration.defaultColorScheme;
        this.DEFAULT_COLOR_THEME = themeConfiguration.defaultColorTheme;
        this.ALLOWED_COLOR_SCHEME = allowedColorScheme;
        this.ALLOWED_COLOR_THEME = allowedColorTheme;

        this.init();
    }

    /**
     * Validation HTML Element.
     * 
     * @returns { void }
     */
    private validationHTML(): void
    {
        const html: HTMLHtmlElement|null = this.HTML_ELEMENT; 

        if(html === null) {
            throw new Error('Root element is not defined');
        }
    }

    /**
     * Validation color scheme and color theme.
     * 
     * @param { string } colorScheme
     * @param { string } colorTheme
     * @returns { void }
     */
    private validation(colorScheme: string, colorTheme: string): void
    {
        if(this.ALLOWED_COLOR_SCHEME.includes(colorScheme) === false) {
            throw new Error("Theme is not allowed");
        }

        if(this.ALLOWED_COLOR_THEME.includes(colorTheme) === false) {
            throw new Error("Theme is not allowed");
        }
    }

    /**
     * Set theme attribute and store to localStorage.
     * 
     * @param { string } colorScheme
     * @param { string } colorTheme
     * @returns { void }
     */
    private setTheme(colorScheme: string, colorTheme: string): void
    {
        this.validation(colorScheme, colorTheme);

        try {
            this.HTML_ELEMENT?.setAttribute(this.ATTRIBUTE_COLOR_SCHEME, colorScheme);
            this.HTML_ELEMENT?.setAttribute(this.ATTRIBUTE_COLOR_THEME, colorTheme);

            localStorage.setItem(this.KEY_LOCAL_STORAGE_COLOR_SCHEME, colorScheme);
            localStorage.setItem(this.KEY_LOCAL_STORAGE_COLOR_THEME, colorTheme);
        } catch  {
            throw new Error("Failed to create layout theme");
        }
    }

    /**
     * Get current theme.
     * 
     * @returns { CurrentTheme }
     */
    private get current(): { colorScheme: string, colorTheme: string }
    {
        const currentColorScheme: string = localStorage.getItem(this.KEY_LOCAL_STORAGE_COLOR_SCHEME) ?? this.DEFAULT_COLOR_SCHEME;
        const currentColorTheme: string = localStorage.getItem(this.KEY_LOCAL_STORAGE_COLOR_THEME) ?? this.DEFAULT_COLOR_THEME;

        return {
            colorScheme: currentColorScheme,
            colorTheme: currentColorTheme
        }
    }

    /**
     * Initiate theme.
     * 
     * @returns { void }
     */
    private init(): void
    {
        this.validationHTML();
        this.setTheme(this.current.colorScheme, this.current.colorTheme);
    }

    /**
     * Get active color scheme.
     * 
     * @returns { string }
     */
    get activeColorScheme(): string
    {
        const getActiveColorScheme: string|null = localStorage.getItem(this.KEY_LOCAL_STORAGE_COLOR_SCHEME);

        if(getActiveColorScheme === null) {
            throw new Error('Theme is not exists');
        }

        return getActiveColorScheme;
    }

    /**
     * Get active color theme.
     * 
     * @returns { string }
     */
    get activeColorTheme(): string
    {
        const getActiveColorTheme: string|null = localStorage.getItem(this.KEY_LOCAL_STORAGE_COLOR_THEME);
        
        if(getActiveColorTheme === null) {
            throw new Error('Theme is not exists');
        }

        return getActiveColorTheme;
    }

    /**
     * Switch theme.
     * 
     * @param { string } colorScheme
     * @param { string } colorTheme
     * @returns { void }
     */
    public switch(colorScheme: string, colorTheme: string): void
    {
        this.setTheme(colorScheme, colorTheme);
    }
}

export class Mode implements ModeContract
{
    /**
     * HTML tag element.
     * 
     * @type { HTMLHtmlElement | null }
     */
    private readonly HTML_ELEMENT: HTMLHtmlElement|null = document.querySelector('html');

    /**
     * Attribute layout mode.
     * 
     * @type { string }
     */
    private readonly ATTRIBUTE: string;

    /**
     * Key localStorage layout mode.
     * 
     * @type { string }
     */
    private readonly KEY_LOCAL_STORAGE: string;

    /**
     * Allowed layout mode.
     * 
     * @type { ReadonlyArray<string> }
     */
    private readonly ALLOWED: ReadonlyArray<string>;

    /**
     * Default layout mode.
     * 
     * @type { string }
     */
    private readonly DEFAULT: string;

    /**
     * Constructor
     */
    constructor()
    {
        this.ATTRIBUTE = mode.attribute;
        this.KEY_LOCAL_STORAGE = mode.keyLocalStorage;
        this.ALLOWED = allowedLayoutMode;
        this.DEFAULT = mode.default;

        this.init();
    }

    /**
     * Get current layout mode.
     * 
     * @returns { string }
     */
    private get current(): string
    {
        return localStorage.getItem(this.KEY_LOCAL_STORAGE)|| this.DEFAULT;
    }

    /**
     * Validate property.
     * 
     * @param { string } mode
     * @returns { void }
     */
    private validate(mode: string): void
    {
        const html: HTMLHtmlElement|null = this.HTML_ELEMENT;

        if(html === null) {
            throw new Error('Root element is not defined');
        }

        if(this.ALLOWED.includes(mode) === false) {
            throw new Error('Layout mode is not allowed');
        }
    }

    /**
     * Setup layout mode.
     * 
     * @param { string } mode
     * @returns { void }
     */
    private setup(mode: string): void
    {        
        this.validate(mode);
        
        try {
            this.HTML_ELEMENT?.setAttribute(this.ATTRIBUTE, mode);
            localStorage.setItem(this.KEY_LOCAL_STORAGE, mode);
        } catch {
            throw new Error("Failed to create layout mode");
        }
    }

    /**
     * Initiate layout mode.
     * 
     * @returns { void }
     */
    private init(): void
    {
        this.setup(this.current);
    }

    /**
     * Get active or current layout.
     * 
     * @returns { string }
     */
    get active(): string
    {
        const currentActiveLayoutMode: string|null = localStorage.getItem(this.KEY_LOCAL_STORAGE);

        if(currentActiveLayoutMode === null) {
            throw new Error('Mode is not exists');
        }

        return currentActiveLayoutMode;
    }

    /**
     * Switch layout mode.
     * 
     * @param { string } mode
     * @returns { void }
     */
    public switch(mode: string): void
    {
        this.setup(mode);
    }
}